import React from "react"
import { Link } from "gatsby"

import Layout from "@components/layout"
import SEO from "@components/seo"

import ServicesBanner from "@images/hero-bg5.jpeg"
import DATA from "@images/data.png"
import HOURLY from "@images/hourly.png"
import MANAGE from "@images/manage.png"
import NOPROFIT from "@images/nopayment.png"

const Services = () => (
	<Layout>
		<SEO
			title="Services by NeighborhoodIT | Boulder County"
			description="To learn more about services by NeighborhoodIT call us today at (303) 408-6028 or visit our website for more information."
		/>
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${ServicesBanner})`,
				backgroundPosition: `bottom`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white">
						<div className="col-12">
							<h1>Services by NeighborhoodIT</h1>
							<p>
								Putting computer and network services back in the hands of the
								professionals.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="services-page" className="page-content">
			<div className="container-fluid">
				<div className="row justify-content-between align-items-center text-center">
					<div className="col-lg-3 col-sm-12 mb-5">
						<img className="img-fluid" src={DATA} alt="Data Protection" />
						<h3>Data Protection</h3>
						<p>
							Protect your hard work with simple, reliable and cost-effective
							data protection services
						</p>
						<div>
							<Link to="/data-protection" className="hero-cta">
								Read More
							</Link>
						</div>
					</div>
					<div className="col-lg-3 col-sm-12 mb-5">
						<img className="img-fluid" src={HOURLY} alt="Hourly It Support" />
						<h3>Hourly IT Support</h3>
						<p>
							Keep your business running smoothly with timely assistance from
							our friendly IT experts.
						</p>
						<div>
							<Link to="/hourly-it" className="hero-cta">
								Read More
							</Link>
						</div>
					</div>
					<div className="col-lg-3 col-sm-12 mb-5">
						<img className="img-fluid" src={MANAGE} alt="Manage Services" />
						<h3>Managed IT Services</h3>
						<p>
							Cut distractions, headaches and unpredictable IT costs with our
							popular managed services.
						</p>
						<div>
							<Link to="/managed-services" className="hero-cta">
								Read More
							</Link>
						</div>
					</div>
					<div className="col-lg-3 col-sm-12 mb-5">
						<img
							className="img-fluid"
							src={NOPROFIT}
							alt="Not for Profit Home Services"
						/>
						<h3>Not-for-Profit Home Services</h3>
						<p>
							Residential services provided to members of our community on a
							not-for-profit basis.
						</p>
						<div>
							<Link to="/not-for-profit" className="hero-cta">
								Read More
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Services
